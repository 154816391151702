<template>
  <div>
    <HeaderBanner
      :title="headerText.title"
      :small="headerText.small"
      :img="headerText.img"
    ></HeaderBanner>
    <div class="titleBox">
      <h2>全链路服务，一站式解决企业营销问题</h2>
      <p>
        打通账号搭建、内容生产、内容发布、数据分析，帮助企业构建数字化营销闭环
      </p>
    </div>
    <div class="widthCenter flex between cardBox">
      <div class="card">
        <img src="../assets/imgs/index/icon1.png" alt="" />
        <div>
          <h4>搭建营销账号</h4>
          <p>
            从0开始搭建多平台账号，包括快手、哔哩哔哩、头条号、百家号、知乎、百度知道等自媒体平台账号，以及腾讯广告、巨量引擎、磁力智投等广告投放平台账号...
          </p>
        </div>
      </div>
      <div class="card">
        <img src="../assets/imgs/index/icon2.png" alt="" />
        <div>
          <h4>助力内容生产</h4>
          <p>
            AI智能文案，激发灵感，一键生成高质量文案；海量设计模板，覆盖广告投放、邮件推广、新媒体运营等多种使用场景...
          </p>
        </div>
      </div>
    </div>
    <div class="widthCenter flex between cardBox">
      <div class="card">
        <img src="../assets/imgs/index/icon3.png" alt="" />
        <div>
          <h4>高效触达用户</h4>
          <p>
            海量媒体流量，精细化用户标签，定向投放；多样化广告类型、多场景触达，提高用户转化率；多平台、多账号管理，一键发布...
          </p>
        </div>
      </div>
      <div class="card">
        <img src="../assets/imgs/index/icon4.png" alt="" />
        <div>
          <h4>沉淀数据分析</h4>
          <p>
            打通多平台数据孤岛，全链路检测数据，提供全场景、多维度数据分析管理能力；可视化数据看板，让内容营销数据效果一目了然...
          </p>
        </div>
      </div>
    </div>
    <div class="titleBox" style="margin-top: 120px">
      <h2>多场景推广，根据行业属性定制营销方案</h2>
      <p>
        聚合海量用户，提供投放、邮箱、新媒体内容多种推广方式，根据客户需求和行为属性定制最优方案
      </p>
    </div>
    <div class="widthCenter flex between knowBox">
      <div>
        <div class="img">
          <img src="../assets/imgs/index/icon5.png" alt="" />
        </div>
        <h4>投放营销</h4>
        <p>聚合全网海量APP用户</p>
        <p>让投放获客更高效</p>
        <span><router-link to="./put">了解更多>></router-link></span>
      </div>
      <div>
        <div class="img">
          <img src="../assets/imgs/index/icon6.png" alt="" />
        </div>
        <h4>邮箱营销</h4>
        <p>海量优质IP，精准用户标签</p>
        <p>让邮箱推广更简单</p>
        <span><router-link to="./email">了解更多>></router-link></span>
      </div>
      <div>
        <div class="img">
          <img src="../assets/imgs/index/icon10.png" alt="" />
        </div>
        <h4>新媒体营销</h4>
        <p>多平台账号一键发布</p>
        <p>让新媒体运营更轻松</p>
        <span><router-link to="./media">了解更多>></router-link></span>
      </div>
    </div>
    <div class="titleBox" style="margin-top: 120px">
      <h2>专家级服务团队，帮助企业快速成功</h2>
      <p>服务团队专业细致，助力企业客户高效营销，极速响应，保障服务稳定通畅</p>
    </div>
    <div class="widthCenter flex between circle">
      <div>
        <img src="../assets/imgs/index/icon7.png" alt="" />
        <h4>文案策划</h4>
        <p>紧跟时下热点撰写有亮点文案</p>
      </div>
      <div>
        <img src="../assets/imgs/index/icon8.png" alt="" />
        <h4>设计师</h4>
        <p>结合产品优势设计有创意的素材</p>
      </div>
      <div>
        <img src="../assets/imgs/index/icon11.png" alt="" />
        <h4>优化师</h4>
        <p>根据考核目标优化营销效果数据</p>
      </div>
      <div>
        <img src="../assets/imgs/index/icon9.png" alt="" />
        <h4>技术支持</h4>
        <p>负责API、RTA等技术对接支持</p>
      </div>
    </div>
    <div class="titleBox" style="margin-top: 120px">
      <h2>深耕数字化营销领域，提供定制化解决方案</h2>
      <p>针对不同行业营销痛点，定制全方位的解决方案，助力客户成功</p>
    </div>
    <div class="widthCenter">
      <el-tabs v-model="activeName" stretch>
        <el-tab-pane label="投放营销方案" name="first" class="tab1">
          <h4>电商行业借助广告投放高效获客转化</h4>
          <div style="display: flex" class="between">
            <div class="leftBox">投 放 营 销 & 电 商 行 业</div>
            <ul class="centerBox">
              <li>行业客户述求</li>
              <li>获取新用户下载注册</li>
              <li>促进老用户调起下单</li>
            </ul>
            <ul class="rightBox">
              <li>解决方案：强曝光、高转化</li>
              <li>
                多媒体、多形式增强曝光
                <ul>
                  <li>开屏</li>
                  <li>信息流</li>
                  <li>Banner</li>
                </ul>
              </li>
              <li>
                数据对接、算法模型提高转化
                <ul>
                  <li>API</li>
                  <li>RTA</li>
                  <li>oCPX</li>
                </ul>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="邮箱营销方案" name="second" class="tab2">
          <h4>邮箱营销助力贸易行业开拓庞大市场</h4>
          <div style="display: flex; padding: 20px 150px" class="between">
            <div>
              <div class="flex">
                <div class="shu"></div>
                <span class="smallTitle f_weight">贸易行业客户诉求</span>
              </div>
              <div class="box1">
                <div>提升品牌知名度</div>
                <div>拓展市场份额</div>
                <div>增加销售和业绩</div>
                <div>加强客户关系和沟通</div>
                <div>节省推广时间和成本</div>
              </div>
            </div>
            <div>
              <div class="flex">
                <div class="shu"></div>
                <span class="smallTitle f_weight">创变营销解决方案</span>
              </div>
              <div class="box2">
                <p>精准标签锁定目标</p>
                <div>省份地区</div>
                <div>经营状态</div>
                <div>所属行业</div>
                <div>注册资本</div>
                <div>关键字</div>
              </div>
            </div>
            <div class="flexColumn box3">
              <div>
                <p>自动营销省时降本</p>
                <ul>
                  <li>定制化邮件模板</li>
                  <li>AI智能生成文案</li>
                  <li>批量定时发送</li>
                  <li>支持变量高效定制</li>
                </ul>
              </div>
              <div>
                <p>数据分析驱动效果优化</p>
                <ul>
                  <li>送达率</li>
                  <li>点击率</li>
                  <li>打开率</li>
                  <li>各域名发送情况</li>
                </ul>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="新媒体营销方案" name="third" class="tab3">
          <h4>日化行业通过新媒体营销加强品牌曝光</h4>
          <div class="widthCenter">
            <div class="card flex" style="justify-content: center">
              <div>
                <p>搭建新媒体矩阵账号</p>
                <span
                  >根据日化行业用户特性，搭建小红书、微博、快手、抖音等官方矩阵账号，并筛选匹配达人合作...</span
                >
              </div>
              <img src="../assets/imgs/index/right.png" alt="" />
              <div>
                <p>输出定制化营销内容</p>
                <span
                  >客户可使用图文模板结合品牌特性快速完成内容；专业文案策划和设计师每日更新定制化图文素材...</span
                >
              </div>
            </div>
            <div
              class="flex"
              style="justify-content: end; width: 510px; margin: 0 auto"
            >
              <img src="../assets/imgs/index/down.png" alt="" />
            </div>
            <div class="card flex" style="justify-content: center">
              <div>
                <p>数据分析持续优化效果</p>
                <span
                  >支持多平台、全链路数据追踪，通过后台统一分析内容、渠道效果，不断优化渠道结构和内容方向...</span
                >
              </div>
              <img src="../assets/imgs/index/left.png" alt="" />
              <div>
                <p>多平台高效发布内容</p>
                <span
                  >多平台多账号统一管理，一键发布营销内容，有效节约时间成本，提高人力效率...</span
                >
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <FormEnd></FormEnd>
  </div>
</template>

<script>
import HeaderBanner from "../components/headerBanner.vue";
export default {
  name: "Home",
  components: { HeaderBanner },
  data() {
    return {
      headerText: {
        title: "创变全链路营销解决方案",
        small:
          "整合全媒体流量资源，打通内容生产--内容发布--数据分析全链路，帮助企业构建数字化营销闭环",
        img: require("../assets/imgs/index/banner.png"),
      },
      activeName: "first",
    };
  },

};
</script>


<style scoped lang='scss'>
$radius: 10px;
$box_shadow: 0px 3px 10px #409eff;
.titleBox {
  text-align: center;
  margin: 50px 0;
  h2 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    color: #666;
    font-weight: 600;
  }
}

.cardBox {
  margin-bottom: 40px;
  .card {
    width: 500px;
    height: 160px;
    box-shadow: $box_shadow;
    border-radius: $radius;
    display: flex;
    align-items: center;
    padding: 30px;
    h4 {
      font-size: 18px;
      margin-bottom: 10px;
    }
    p {
      color: #666;
      font-weight: 600;
    }
    img {
      margin-right: 10px;
      width: 80px;
    }
  }
}

.knowBox {
  h4 {
    font-size: 18px;
    line-height: 70px;
  }
  p {
    line-height: 30px;
  }
  h4,
  p {
    text-align: center;
    font-weight: 600;
  }
  span {
    display: block;
    text-align: right;
    margin-top: 30px;
  }
  .img {
    width: 80px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  > div {
    display: block;
    width: 240px;
    padding: 50px 30px;
    box-shadow: $box_shadow;
    border-radius: $radius;
  }
}

.circle {
  > div {
    img {
      width: 200px;
    }
    h4 {
      font-size: 18px;
    }
    p {
      line-height: 30px;
    }
    h4,
    p {
      text-align: center;
      font-weight: 600;
    }
  }
}

::v-deep .el-tabs__item {
  font-size: 20px;
  font-weight: 600;
}
.el-tabs {
  h4 {
    text-align: center;
    font-size: 18px;
    margin: 50px 0;
  }
}
.tab1 {
  padding: 10px 150px;
  .leftBox {
    padding: 100px 20px;
    writing-mode: vertical-rl;
    font-weight: 600;
    font-size: 16px;
  }
  .centerBox li {
    padding: 50px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .centerBox,
  .rightBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .centerBox li:nth-child(1) {
    padding: 30px;
  }
  > div div,
  .centerBox li {
    box-shadow: $box_shadow;
    border-radius: $radius;
  }

  .rightBox > li {
    padding: 30px 10px 10px 10px;
    box-shadow: $box_shadow;
    border-radius: $radius;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
  .rightBox ul {
    display: flex;
    margin-top: 22px;
    justify-content: center;
  }
  .rightBox ul li {
    padding: 5px 30px;
    box-shadow: $box_shadow;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    margin: 5px 30px;
  }
  .rightBox > li:nth-child(1) {
    padding: 30px;
  }
}

.tab2 {
  h4 {
    margin-bottom: 24px !important;
  }
  padding-top: 10px;
  span {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .box1 {
    > div {
      padding: 10px 15px;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgb(255, 255, 255) 0%,
        rgb(150 178 229) 100%
      );
      margin: 16px 10px;
      border-radius: 5px;
    }
  }
  .box2 {
    padding: 20px 30px !important;
    > div {
      background-color: #3d7be7;
      color: #fff;
      padding: 8px 15px;
      margin: 16px 10px;
      border-radius: 5px;
    }
    p {
      font-size: 16px;
    }
  }
  .box3 {
    text-align: center;
    font-weight: 600;
    p {
      font-size: 16px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      width: 400px;
      justify-content: center;
      margin-top: 12px;
      li {
        background-color: #3d7be7;
        border-radius: 5px;
        width: 180px;
        color: #fff;
        margin: 5px;
        padding: 10px 0;
      }
    }
    > div:first-child {
      margin-top: 38px;
    }
    > div {
      padding: 12px 20px;
      border: 1px solid #ddd;
      border-radius: $radius;
      box-shadow: 0px 1px 5px 2px #ddd;
    }
  }
  .box1,
  .box2 {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: $radius;
    box-shadow: 0px 1px 5px 2px #ddd;
    text-align: center;
    font-weight: 600;
  }
  .shu {
    width: 3px;
    height: 16px;
    background-color: #6093ea;
    margin-right: 5px;
    margin-bottom: 24px;
  }
  .smallTitle {
    color: #6093ea;
  }
}

.tab3 {
  padding-top: 10px;
  padding-bottom: 9px;
  img {
    width: 60px;
    margin: 14px 40px;
  }
  .card {
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 40px;
    }
    > div {
      box-shadow: $box_shadow;
      padding: 20px;
      width: 200px;
      border-radius: $radius;
    }
  }
}
</style>
